import * as React from "react";

export class CommunityEnergy extends React.Component {
    render() {
        return (
            <div className="my-5 ml-custom mr-custom">
                <h3>Community Energy</h3>
                <br/>
                <p>Community energy refers to the delivery of community-led renewable energy, energy demand reduction
                    and energy supply projects, whether wholly owned and/or controlled by communities or through a
                    partnership with commercial or public sector partners.</p>
                <p>(
                    <a
                        href="https://communityenergyengland.org/" target="_blank">Community
                        Energy England</a>
                    )
                    <br/>
                </p>
            </div>
        )
    }
}