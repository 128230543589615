import * as React from "react";

export class CircularEconomy extends React.Component {
    render() {
        return (
            <div className="my-5 ml-custom mr-custom">
                <h3>Circular Economy</h3>
                <br/>
                <p>Three principles</p>
                <ul>
                    <li>Design out waste and pollution</li>
                    <li>Keep products and materials in use</li>
                    <li>Regenerate natural systems</li>
                </ul>
                <br/>
                <p>Looking beyond the current take-make-waste extractive industrial model, a circular economy aims to
                    redefine growth, focusing on positive society-wide benefits. It entails gradually decoupling
                    economic activity from the consumption of finite resources, and designing waste out of the system.
                    Underpinned by a transition to renewable energy sources, the circular model builds economic,
                    natural, and social capital. It is based on three principles:
                </p>
            </div>
        )
    }
}