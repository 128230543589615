import * as React from "react";

function CopyrightDisclaimer() {

    return (
        <div className={'mx-auto auto p-5'} style={{height:'500px'}}>

            <h2>PowerHyper Copyright Disclaimer</h2>
            <br/>
            <p>Much of the content presented is referenced from other external sources and is not
                created by PowerHyper.
            The reused content is presented on the website for educational and research purposes.
                If available, PowerHyper will always reference the original author.
            If any complaints please contact the email address info@powerhyper.org
                and we will respond as quickly as possible.</p>
        </div>
    );
}

export default CopyrightDisclaimer;