import * as React from "react";
import YouTube from "react-youtube";

export class EnergyMarkets extends React.Component {
    render() {
        return (
            <div className="my-5 ml-custom mr-custom">
                <h3>Energy Markets</h3>
                <br/>
                <h5>Increasing complexity</h5>
                <p>The grid is become more interconnected and complicated. Utilities do not just generate power for
                    their own customers, but buy and sell power in a market. With a greater use of renewables, such as
                    solar and wind, the energy markets become even more complex.</p>
            </div>
        )
    }
}